import { Add, Delete } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchConfigData, saveConfigData } from '../../service/index';
import useStore from '../../state/index';

const SiteDetails = () => {
    const [data, setData] = useState([]); // All site data
    const [selectedSiteIndex, setSelectedSiteIndex] = useState(0); // Selected site index
    const [loading, setLoading] = useState(true); // Loading state
    const [saving, setSaving] = useState(false); // Saving state
    const { apiAccessToken, setInfoMsg, setErrorMsg, setWarningMsg, setSuccessMsg } = useStore();

    useEffect(() => {
        const loadData = async () => {
            try {
                const response = await fetchConfigData(apiAccessToken);
                if (response?.data?.length > 0) {
                    setData(response.data);
                    setInfoMsg(response.message);
                } else {
                    setErrorMsg('No site data found.');
                }
            } catch (error) {
                console.error('Error fetching site details:', error);
                setErrorMsg('Error fetching site details.');
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [apiAccessToken]);

    const handleSiteChange = (event) => {
        setSelectedSiteIndex(event.target.value);
    };

    // Parse Key Field
    const parseKey = (key) => {
        const [macAddress, hostName] = key.split('#');
        return { macAddress, hostName };
    };

    const updateKey = (field, value) => {
        const updatedData = [...data];
        const site = { ...updatedData[selectedSiteIndex] };
        site[field] = `${value}`;
        updatedData[selectedSiteIndex] = site;
        setData(updatedData);
    };

    const handleDenominationChange = (index, value) => {
        const updatedData = [...data];
        updatedData[selectedSiteIndex].denominations[index] = Number(value);
        setData(updatedData);
    };

    const handleAddDenomination = () => {
        const updatedData = [...data];
        const denominations = updatedData[selectedSiteIndex].denominations || [];
        denominations.push(0);
        updatedData[selectedSiteIndex].denominations = denominations;
        setData(updatedData);
    };

    const handleRemoveDenomination = (index) => {
        const updatedData = [...data];
        const denominations = updatedData[selectedSiteIndex].denominations || [];
        updatedData[selectedSiteIndex].denominations = denominations.filter((_, i) => i !== index);
        setData(updatedData);
    };

    const handleShiftChange = (index, field, value) => {
        const updatedData = [...data];
        const shifts = updatedData[selectedSiteIndex].shifts || [];
        shifts[index][field] = Number(value);
        updatedData[selectedSiteIndex].shifts = shifts;
        setData(updatedData);
    };

    const handleAddShift = () => {
        const updatedData = [...data];
        const shifts = updatedData[selectedSiteIndex].shifts || [];
        shifts.push({
            startHour: 0,
            endHour: 0,
            order: shifts.length + 1,
        });
        updatedData[selectedSiteIndex].shifts = shifts;
        setData(updatedData);
    };

    const handleRemoveShift = (index) => {
        const updatedData = [...data];
        const shifts = updatedData[selectedSiteIndex].shifts || [];
        updatedData[selectedSiteIndex].shifts = shifts.filter((_, i) => i !== index);
        setData(updatedData);
    };

    const handleSave = async () => {
        setSaving(true);
        try {
            await saveConfigData(apiAccessToken, data[selectedSiteIndex]);
            setSuccessMsg('Config saved successfully.');
        } catch (error) {
            console.error('Error saving site details:', error);
            setErrorMsg('Error saving site details.');
        } finally {
            setSaving(false);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (data.length === 0) {
        return <Typography color="error">No site data available.</Typography>;
    }

    const selectedSite = data[selectedSiteIndex];

    return (
        <Box>
            <Typography variant="h6">Edit Site Details</Typography>

            {/* Site Selector */}
            <TextField select label="Select Machine" value={selectedSiteIndex} onChange={handleSiteChange} fullWidth margin="normal">
                {data.map((site, index) => (
                    <MenuItem key={site.siteId} value={index}>
                        {parseKey(site.key).hostName}
                    </MenuItem>
                ))}
            </TextField>

            <Typography variant="subtitle1" mt={2}>
                Valid Until
            </Typography>
            <TextField
                label="Valid Until"
                value={selectedSite.validUntil}
                InputProps={{
                    readOnly: true,
                }}
                variant="outlined" // Use "outlined" or "filled" as per your theme
                fullWidth
                margin="normal"
            />

            {/* Key Fields (MAC Address and Host Name) */}
            <Typography variant="subtitle1" mt={2}>
                MAC Address
            </Typography>
            <TextField
                fullWidth
                value={selectedSite.macAddress}
                InputProps={{
                    readOnly: true,
                }}
                onChange={(e) => updateKey('macAddress', e.target.value)}
                margin="normal"
                label="MAC Address"
            />

            <Typography variant="subtitle1" mt={2}>
                Host Name
            </Typography>
            <TextField
                fullWidth
                value={selectedSite.hostName}
                InputProps={{
                    readOnly: true,
                }}
                onChange={(e) => updateKey('hostName', e.target.value)}
                margin="normal"
                label="Host Name"
            />

            {/* Denominations Section */}
            <Typography variant="subtitle1" mt={4} mb={3}>
                Denominations
            </Typography>
            <Box>
                {selectedSite.denominations && selectedSite.denominations.length > 0 ? (
                    selectedSite.denominations.map((denomination, index) => (
                        <Box key={index} display="flex" alignItems="center" gap={1} mb={3}>
                            <TextField label={`Denomination ${index + 1}`} type="number" value={denomination} onChange={(e) => handleDenominationChange(index, e.target.value)} size="small" />
                            <IconButton onClick={() => handleRemoveDenomination(index)} color="error">
                                <Delete />
                            </IconButton>
                        </Box>
                    ))
                ) : (
                    <Typography>No denominations available.</Typography>
                )}
                <Button startIcon={<Add />} onClick={handleAddDenomination}>
                    Add Denomination
                </Button>
            </Box>

            {/* Shifts Section */}
            <Typography variant="subtitle1" mt={4} mb={3}>
                Shifts
            </Typography>
            {selectedSite.shifts && selectedSite.shifts.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Shift</TableCell>
                            <TableCell>Start Hour</TableCell>
                            <TableCell>End Hour</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedSite.shifts.map((shift, index) => (
                            <TableRow key={shift.order}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                    <TextField type="number" value={shift.startHour} onChange={(e) => handleShiftChange(index, 'startHour', e.target.value)} size="small" />
                                </TableCell>
                                <TableCell>
                                    <TextField type="number" value={shift.endHour} onChange={(e) => handleShiftChange(index, 'endHour', e.target.value)} size="small" />
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleRemoveShift(index)} color="error">
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Typography>No shifts available.</Typography>
            )}
            <Button startIcon={<Add />} onClick={handleAddShift} sx={{ mt: 1 }}>
                Add Shift
            </Button>

            {/* Save Button */}
            <Box mt={4}>
                <Button variant="contained" onClick={handleSave} disabled={saving} sx={{ mr: 2 }}>
                    {saving ? 'Saving...' : 'Save'}
                </Button>
            </Box>
        </Box>
    );
};

export default SiteDetails;
