// src/components/Header/Header.js
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import React from 'react';
import useStore from '../../state/index';
import NotificationRibbon from '../NotificationRibbon/NotificationRibbon';

const Header = () => {
    const { loggedInUserName, globalMessage, messageLevel } = useStore();

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            window.location.reload();
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <AppBar position="fixed">
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Typography variant="h6" component="div">
                    Game Fire
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" component="div" sx={{ mr: 2 }}>
                        Welcome, {loggedInUserName}
                    </Typography>
                    <Button color="inherit" onClick={handleSignOut}>
                        Sign Out
                    </Button>
                </Box>
            </Toolbar>
            <NotificationRibbon message={globalMessage} severity={messageLevel} />
        </AppBar>
    );
};

export default Header;
