import React, { useEffect, useState } from 'react';
import {
    Button,
    Grid,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    IconButton,
} from '@mui/material';
import './DataTable.scss';
import useStore from '../../state/index';
import { fetchReportingData } from '../../service/index';
import CloseIcon from '@mui/icons-material/Close';

const DataTable = () => {
    const [apiResponse, setApiResponse] = useState({
        data: [],
        message: 'No data available, Click Search to load data',
    });
    const [sortBy, setSortBy] = useState('currentCheckInDt');
    const [sortDirection, setSortDirection] = useState('desc');
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [action, setAction] = useState('Check_In');
    const [actionText, setActionText] = useState(() => (action === 'Registration' ? 'Registrations' : 'Check-Ins'));
    const [fromDate, setFromDate] = useState(() => new Date().toISOString().split('T')[0]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState([]);
    // State to manage the image preview dialog open/close and selected image
    const [isImagePreviewOpen, setImagePreviewOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null); // Stores the imgBase64 of the selected image

    // Function to open the image preview dialog with the clicked image
    const openImagePreview = (imgBase64) => {
        setSelectedImage(imgBase64); // Set the selected image data
        setImagePreviewOpen(true);
    };

    // Function to close the image preview dialog
    const closeImagePreview = () => {
        setImagePreviewOpen(false);
        setSelectedImage(null); // Clear selected image data on close
    };
    const { apiAccessToken, setInfoMsg } = useStore();

    const loadDataFromServer = async (action, fromDateOrName) => {
        try {
            const response = await fetchReportingData(apiAccessToken, action, fromDateOrName);
            setApiResponse(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        loadDataFromServer('Check_In', new Date());
    }, [apiAccessToken]);

    useEffect(() => {
        if (apiResponse) {
            setInfoMsg(apiResponse.message);
        }
    }, [apiResponse]);

    const filterData = () => {
        if (apiResponse) {
            setFilteredData(apiResponse.data.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()) || String(item.phone).includes(searchQuery)));
        }
    };

    useEffect(() => {
        filterData();
    }, [searchQuery, apiResponse]);

    const handleSort = (column) => {
        const isAsc = sortBy === column && sortDirection === 'asc';
        setSortBy(column);
        setSortDirection(isAsc ? 'desc' : 'asc');
    };

    const sortedData = filteredData.sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];

        if (['createTs', 'currentCheckInDt', 'lastCheckInDt'].includes(sortBy)) {
            return sortDirection === 'asc' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
        }

        return 0;
    });

    const totalCounter = filteredData.length;
    const totalDenominations = filteredData.reduce((total, item) => total + (item.denomination || 0), 0);
    const displayedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleSearchBtnClick = () => {
        setActionText(action === 'Registration' ? 'Registrations' : 'Check-Ins');
        loadDataFromServer(action, fromDate);
        setSearchQuery('');
    };

    const handleDialogOpen = async (row) => {
        try {
            const response = await fetchReportingData(apiAccessToken, 'UserDetails', row.name);
            setSelectedUserData(response.data.sort((a, b) => new Date(b.createTs) - new Date(a.createTs)));
            setIsDialogOpen(true); // Open the dialog
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setSelectedUserData(null); // Clear the selected user data when dialog closes
    };

    return (
        <TableContainer component={Paper}>
            <div className="table-header">
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <div className="totals">
                            <Typography variant="h6">
                                Total {actionText}: {totalCounter}
                            </Typography>
                            <Typography variant="h6">Total Denominations: ${totalDenominations}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} direction="row" alignItems="center" justifyContent="flex-start">
                            <Grid item xs={12} sm={3} style={{ paddingRight: '8px' }}>
                                <TextField
                                    label="Date"
                                    type="date"
                                    variant="outlined"
                                    onChange={(e) => setFromDate(e.target.value)}
                                    value={fromDate}
                                    InputLabelProps={{ shrink: true }}
                                    style={{ minWidth: '150px' }}
                                />
                                <Select label="Filter By" value={action} onChange={(e) => setAction(e.target.value)} style={{ minWidth: '150px' }}>
                                    <MenuItem value="Registration">Registrations</MenuItem>
                                    <MenuItem value="Check_In">Check Ins</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Button variant="contained" color="primary" onClick={handleSearchBtnClick} style={{ height: '56px', minWidth: '100px' }}>
                                    Search
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ paddingRight: '8px' }}>
                                <TextField label="Filter" variant="outlined" onChange={(e) => setSearchQuery(e.target.value)} value={searchQuery} style={{ minWidth: '250px' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        {['Photo', 'Name', 'Phone', 'Action', 'Current Check In', 'Last Check In', 'Denomination', 'Site'].map((column) => (
                            <TableCell key={column} onClick={() => handleSort(column)} style={{ cursor: 'pointer' }}>
                                <strong>
                                    {column}
                                    {sortBy === column ? (sortDirection === 'asc' ? ' ↑' : ' ↓') : null}
                                </strong>
                            </TableCell>
                        ))}
                        {action === 'Check_In' && (
                            <TableCell>
                                <strong>Details</strong>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {displayedData.length > 0 ? (
                        displayedData.map((item, index) => (
                            <TableRow key={index}>
                                <>
                                    <TableCell>
                                        {item.imgBase64 ? (
                                            // Clickable image to open the dialog with the selected image
                                            <img
                                                src={`data:image/jpeg;base64,${item.imgBase64}`}
                                                alt="thumbnail"
                                                style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                                                onClick={() => openImagePreview(item.imgBase64)} // Pass the clicked image data
                                            />
                                        ) : (
                                            'N/A'
                                        )}
                                    </TableCell>
                                    {/* Dialog component for showing the larger image */}
                                    <Dialog
                                        open={isImagePreviewOpen}
                                        onClose={closeImagePreview}
                                        maxWidth="xl"
                                        PaperProps={{
                                            style: {
                                                backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dialog background, semi-transparent
                                                boxShadow: 'none',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                overflow: 'hidden',
                                                position: 'relative',
                                                padding: '0',
                                            },
                                        }}
                                        BackdropProps={{
                                            style: {
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Lighter semi-transparent backdrop
                                            },
                                            transitionDuration: 300, // Smooth fade-in for the backdrop
                                        }}
                                        disableEscapeKeyDown // Optional: prevents closing on pressing the Escape key
                                        onBackdropClick={(e) => e.stopPropagation()} // Prevents closing on backdrop click
                                    >
                                        {/* Close button at the top-right corner */}
                                        <IconButton
                                            onClick={closeImagePreview}
                                            style={{
                                                position: 'absolute',
                                                top: 16,
                                                right: 16,
                                                color: 'white',
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                zIndex: 1,
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>

                                        <DialogContent
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: 0,
                                                overflow: 'hidden',
                                            }}
                                        >
                                            {selectedImage && (
                                                <img
                                                    src={`data:image/jpeg;base64,${selectedImage}`}
                                                    alt="thumbnail-large"
                                                    style={{
                                                        width: '30vw',
                                                        height: 'auto',
                                                        maxWidth: '50vw',
                                                        maxHeight: '50vh',
                                                        borderRadius: '1px',
                                                    }}
                                                />
                                            )}
                                        </DialogContent>
                                    </Dialog>
                                </>
                                <TableCell>{item.name || 'N/A'}</TableCell>
                                <TableCell>{item.phone || 'N/A'}</TableCell>
                                <TableCell>{item.action || 'N/A'}</TableCell>
                                <TableCell>{item.currentCheckInDt ? new Date(item.currentCheckInDt).toLocaleString() : 'N/A'}</TableCell>
                                <TableCell>{item.lastCheckInDt ? new Date(item.lastCheckInDt).toLocaleString() : 'N/A'}</TableCell>
                                <TableCell>${item.denomination || 'N/A'}</TableCell>
                                <TableCell>{item.siteId || 'N/A'}</TableCell>
                                {action === 'Check_In' && (
                                    <TableCell>
                                        <Button variant="contained" onClick={() => handleDialogOpen(item)}>
                                            View Details
                                        </Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                                <Typography>No records found</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
            />

            <>
                {/* Dialog for viewing user details */}
                <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
                    <DialogTitle>User Details</DialogTitle>
                    <DialogContent dividers>
                        {selectedUserData ? (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {['Photo', 'Name', 'Phone', 'Action', 'Current Check In', 'Last Check In', 'Denomination', 'Site'].map((column) => (
                                                <TableCell key={column} onClick={() => handleSort(column)} style={{ cursor: 'pointer' }}>
                                                    <strong>
                                                        {column}
                                                        {sortBy === column ? (sortDirection === 'asc' ? ' ↑' : ' ↓') : null}
                                                    </strong>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedUserData.length > 0 ? (
                                            selectedUserData.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {item.imgBase64 ? (
                                                            <img
                                                                src={`data:image/jpeg;base64,${item.imgBase64}`}
                                                                alt="thumbnail"
                                                                style={{ width: '140px', height: '140px', objectFit: 'cover' }} // Scaling up the image
                                                            />
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </TableCell>
                                                    <TableCell>{item.name || 'N/A'}</TableCell>
                                                    <TableCell>{item.phone || 'N/A'}</TableCell>
                                                    <TableCell>{item.action || 'N/A'}</TableCell>
                                                    <TableCell>{item.currentCheckInDt ? new Date(item.currentCheckInDt).toLocaleString() : 'N/A'}</TableCell>
                                                    <TableCell>{item.lastCheckInDt ? new Date(item.lastCheckInDt).toLocaleString() : 'N/A'}</TableCell>
                                                    <TableCell>${item.denomination || 'N/A'}</TableCell>
                                                    <TableCell>{item.siteId || 'N/A'}</TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                                                    <Typography>No records found</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <CircularProgress style={{ display: 'block', margin: 'auto' }} />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </TableContainer>
    );
};

export default DataTable;
