import React, { useState } from 'react';
import { Box, List, ListItem, ListItemText, Drawer, IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useStore from '../../state/index';
import { fetchReportingData } from '../../service/index';

const Sidebar = () => {
    const { apiAccessToken, setSuccessMsg, setShowComponent, userRole } = useStore();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Drawer state for mobile
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm')); // Responsive check for small screens

    const getDataAndSetMsg = async () => {
        try {
            const response = await fetchReportingData(apiAccessToken, 'Registration', '');
            setSuccessMsg(response.message);
        } catch (error) {
            console.error('Error fetching total registrations:', error);
        }
    };

    const handleShowSiteDetails = () => {
        setShowComponent('ConfigDetails');
    };

    const handleShowHome = () => {
        setShowComponent('DataTable');
    };

    const sidebarContent = (
        <List>
            <ListItem button onClick={handleShowHome}>
                <ListItemText primary="Home" />
            </ListItem>
            <ListItem button onClick={getDataAndSetMsg}>
                <ListItemText primary="Total Registrations" />
            </ListItem>
            {userRole !== 'Employee' && (
                <ListItem button onClick={handleShowSiteDetails}>
                    <ListItemText primary="Site Details" />
                </ListItem>
            )}
        </List>
    );

    return (
        <Box>
            {isMobile ? (
                // Mobile view with Drawer
                <>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setIsDrawerOpen(true)} sx={{ position: 'absolute', top: 16, left: 16 }}>
                        <MenuIcon />
                    </IconButton>
                    <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                        <Box sx={{ width: 200, p: 2 }} role="presentation" onClick={() => setIsDrawerOpen(false)} onKeyDown={() => setIsDrawerOpen(false)}>
                            {sidebarContent}
                        </Box>
                    </Drawer>
                </>
            ) : (
                // Desktop view with static sidebar
                <Box className="sideBar" sx={{ width: 200, marginTop: 8, bgcolor: 'grey.200', p: 2 }}>
                    {sidebarContent}
                </Box>
            )}
        </Box>
    );
};

export default Sidebar;
