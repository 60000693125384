const get2AMInLocalTimeAsUTC = (date) => {
    // console.log('Received ', date);
    if (!date || date === '') {
        return date;
    }

    // Check if `date` is a string and parse it, otherwise assume it's a Date object
    if (typeof date === 'string') {
        const [year, month, day] = date.split('-').map(Number);
        // Create a Date object in local time with the given year, month, and day
        date = new Date(year, month - 1, day); // Months are zero-indexed
    } else if (!(date instanceof Date)) {
        throw new Error('Invalid date format');
    }

    // console.log('Parsed (local time)', date);

    // Set the time to 2 AM in the local timezone
    date.setHours(2, 0, 0, 0); // 2:00 AM local time

    // Convert to ISO string (UTC)
    const utcDateString = date.toISOString();

    // console.log('Converted to UTC', utcDateString);
    return utcDateString;
};

export const fetchReportingData = async (apiAccessToken, action, fromDateOrName) => {
    if (apiAccessToken === '') {
        return;
    }
    let apiEndpoint = '';
    if (action === 'UserDetails') {
        apiEndpoint = `https://api.quikwish.com/report?action=${action}&name=${fromDateOrName}`;
    } else {
        fromDateOrName = get2AMInLocalTimeAsUTC(fromDateOrName);
        apiEndpoint = `https://api.quikwish.com/report?action=${action}&fromDate=${fromDateOrName}`;
    }
    try {
        const response = await fetch(apiEndpoint, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${apiAccessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });

        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload();
            }
            return { data: [], message: 'Error fetching data. Please try again.', status: response.status };
        }

        const result = await response.json();
        return { data: result.data || [], message: result.message, status: response.status };
    } catch (error) {
        console.error('Error fetching data:', error);
        return { data: [], message: 'Error fetching data. Please try again.', status: 500 };
    }
};

export const fetchConfigData = async (apiAccessToken) => {
    if (apiAccessToken === '') {
        return { data: [], message: '' };
    }
    let apiEndpoint = 'https://api.quikwish.com/config';
    try {
        const response = await fetch(apiEndpoint, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${apiAccessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });

        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload();
            }
            return { data: [], message: 'Error fetching data. Please try again.', status: response.status };
        }

        const result = await response.json();
        result.forEach((item) => {
            if (item.key) {
                const [part1, part2] = item.key.split('#'); // Split the key on '#'
                item.macAddress = part1;
                item.hostName = part2;
            }
        });
        return { data: result || [], message: 'Successfully Loaded Config Data', status: response.status };
    } catch (error) {
        console.error('Error fetching data:', error);
        return { data: [], message: 'Error fetching data. Please try again.', status: 500 };
    }
};

export const saveConfigData = async (apiAccessToken, configData) => {
    if (apiAccessToken === '') {
        return { data: [], message: '' };
    }
    delete configData.siteId;
    delete configData.validUntil;
    let apiEndpoint = 'https://api.quikwish.com/config';
    try {
        const response = await fetch(apiEndpoint, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${apiAccessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(configData),
        });

        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload();
            }
            return { data: [], message: 'Error Updating Config Data.', status: response.status };
        }

        return { data: [], message: 'Succesfully Saved Config Data', status: response.status };
    } catch (error) {
        console.error('Error fetching data:', error);
        return { data: [], message: 'Error Updating Config Data.', status: 500 };
    }
};
