// src/App.js
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import Layout from './components/Layout/Layout';
import { Auth } from 'aws-amplify';

// Set inactivity timeout (e.g., 15 minutes)
const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minutes in milliseconds
let inactivityTimer;

// Function to reset the inactivity timer
function resetInactivityTimer() {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(logout, INACTIVITY_TIMEOUT);
}

// Function to log out the user
async function logout() {
    try {
        await Auth.signOut();
        console.log('User has been logged out due to inactivity');
    } catch (error) {
        console.error('Error signing out:', error);
    }
}

// Event listeners to track user activity and reset the timer
window.addEventListener('mousemove', resetInactivityTimer);
window.addEventListener('keypress', resetInactivityTimer);
window.addEventListener('click', resetInactivityTimer);
window.addEventListener('scroll', resetInactivityTimer);

// Start the inactivity timer on page load
resetInactivityTimer();

Amplify.configure({
    Auth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_kc9zXLYXh',
        userPoolWebClientId: '72thfdt8qv21oismhu9jafpp4l',
    },
});

function App() {
    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Layout>
                            <Home />
                        </Layout>
                    }
                />
            </Routes>
        </Router>
    );
}

export default withAuthenticator(App, { hideSignUp: true });
